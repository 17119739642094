import { CustomFile, isValidFile, DEFAULT_ACCEPTED_FILETYPES } from "lib/helpers/isValidFile"
import { DropzoneOptions, FileRejection } from "react-dropzone"
import { useToast } from "./useToast"
import { useBetterTranslation } from "./useTranslation"
import * as path from "path"
import * as Sentry from "@sentry/nextjs"
import { UseFormClearErrors } from "react-hook-form"
import Compressor from "compressorjs"

export const useValidateFiles = () => {
  const toast = useToast()
  const bt = useBetterTranslation()

  const validateFiles = async (
    newFiles: CustomFile[],
    rejectedFiles: FileRejection[],
    name?: string,
    clearErrors?: UseFormClearErrors<any>,
    dropzoneOptions?: Omit<DropzoneOptions, "multiple" | "onDrop">,
    acceptAllFiletypes?: boolean,
    customFiletypes?: string[],
  ) => {
    if (newFiles.length === 0) return

    const processedFiles: CustomFile[] = []

    await Promise.all(
      newFiles.map(async (file) => {
        const ext = file.path ? path.parse(file.path).ext : undefined

        if (!isValidFile(file, bt, toast, dropzoneOptions?.maxSize, acceptAllFiletypes, customFiletypes))
          return

        // Catch any other rejections
        if (rejectedFiles.length > 0) {
          toast({ status: "error", description: bt({ en: "Invalid file", nl: "Ongeldig bestand" }) })
          return
        }

        if (ext && DEFAULT_ACCEPTED_FILETYPES.images.includes(ext)) {
          await new Promise<void>((resolve, reject) => {
            new Compressor(file, {
              quality: 0.2, // not recommended to go below 0.6, but have tested and the quality of 0.2 should be good enough
              success(compressedFile) {
                name && clearErrors?.(name)
                processedFiles.push(compressedFile as CustomFile)
                resolve()
              },
              error(e) {
                Sentry.captureException(`MultiFileInput Image compression error: ${e}`)
                console.log(e.message)
                reject()
              },
            })
          })
        } else {
          name && clearErrors?.(name)
          processedFiles.push(file)
        }
      }),
    )
    return processedFiles
  }

  return validateFiles
}
