export enum UploadType {
  Expense = "EXPENSE",
  Invoice = "INVOICE",
  BankStatement = "BANK_STATEMENT",
  Attachment = "ATTACHMENT",
  Report = "REPORT",
}

export const UPLOAD_TYPE = {
  [UploadType.Expense]: { en: "expenses", nl: "uitgaven" },
  [UploadType.Invoice]: { en: "invoices", nl: "omzetfacturen" },
  [UploadType.BankStatement]: { en: "bank statements", nl: "bankafschriften" },
  [UploadType.Attachment]: { en: "attachments", nl: "bijlages" },
  [UploadType.Report]: { en: "reports", nl: "rapportages" },
}
